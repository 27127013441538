/*
 * @Author: ChenYaJin
 * @Date: 2023-11-06 17:56:48
 * @LastEditors: ChenYaJin
 * @LastEditTime: 2023-11-07 15:48:01
 * @Description: 全局布局配置
 */
import { defineStore } from 'pinia'

export const useConfig = defineStore('config', () => {
  const layout: Layout = reactive({
    // 布局方式，可选值<Default|Classic|Streamline|Double>
    layoutMode: 'Default',
    // 是否暗黑模式
    isDark: false,

    /* 侧边菜单 */
    // 侧边菜单宽度(展开时)，单位px
    menuWidth: 240,
    // 是否水平折叠收起菜单
    menuCollapse: false,
    // 显示菜单栏顶栏(LOGO)
    menuShowTopBar: true,
  })

  function menuWidth() {
    return layout.menuCollapse ? '82px' : layout.menuWidth + 'px'
  }

  const setLayout = (name: keyof Layout, value: any) => {
    layout[name] = value as never
  }

  const setNavTabsWidth = () => {
    const navTabs = document.querySelector('.nav-tabs') as HTMLElement
    if (!navTabs) {
      return
    }
    const navBar = document.querySelector('.nav-bar') as HTMLElement
    const navMenus = document.querySelector('.nav-menus') as HTMLElement
    const minWidth = navBar.offsetWidth - (navMenus.offsetWidth + 20)
    navTabs.style.width = minWidth.toString() + 'px'
  }

  return {
    layout,
    menuWidth,
    setLayout,
    setNavTabsWidth,
  }
})

export interface Layout {
  layoutMode: string
  isDark: boolean
  menuWidth: number
  menuCollapse: boolean
  menuShowTopBar: boolean
}
